import React, { useState, useEffect, useRef } from "react";
//import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./img/DATTMXLogo.png";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function Ocurres() {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [ocurreToDelete, setOcurreToDelete] = useState(null);
  const [ocurreToEdit, setOcurreToEdit] = useState(null);
  const [authorizationKey, setAuthorizationKey] = useState("");
  const [authDialogVisible, setAuthDialogVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [formData, setFormData] = useState({
    calle: "",
    ref: "",
    num: "",
    colonia: "",
    CP: "",
    ciudad: "",
    estado: "",
    nombreNeg: "",
    nombreProp: "",
    nombreEnc: "",
    telContacto: "",
    diasLab: [],
    horaApertura: "",
    horaCierre: "",
    espacioDisp: "",
    recibePaq: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ocurresmx.dev-dattmx.com/api/getocurres"
        );
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const confirmDelete = (ocurre) => {
    setCurrentAction("delete");
    setOcurreToDelete(ocurre);
    setDeleteConfirmation(true);
  };

  const hideDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const hideAuthDialog = () => {
    setAuthDialogVisible(false);
  };

  const hideEditDialog = () => {
    setEditDialogVisible(false);
  };

  const handleDeleteClick = () => {
    setAuthDialogVisible(true);
  };

  const verifyAuthorizationKey = () => {
    if (authorizationKey === "041127") {
      if (currentAction === "delete") {
        deleteOcurres(ocurreToDelete);
      } else if (currentAction === "edit") {
        saveEdit();
      }
      hideAuthDialog();
      hideDeleteConfirmation();
      hideEditDialog();
    } else {
      toast.error("Clave de autorización errónea.");
    }
  };

  const deleteOcurres = async (ocurre) => {
    hideDeleteConfirmation();
    try {
      const response = await fetch(
        `https://ocurresmx.dev-dattmx.com/api/ocurres/${ocurre._id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        toast.success("Registro eliminado correctamente.");
        const newData = data.filter((item) => item._id !== ocurre._id);
        setData(newData);
      } else {
        throw new Error("Error al eliminar el registro");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al eliminar el registro.");
    }
  };

  const confirmEdit = (ocurre) => {
    setCurrentAction("edit");
    setOcurreToEdit(ocurre);
    setFormData({ ...ocurre });
    setEditDialogVisible(true);
  };

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setFormData((prevData) => ({
          ...prevData,
          diasLab: [...prevData.diasLab, value],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          diasLab: prevData.diasLab.filter((day) => day !== value),
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const saveEdit = async () => {
    try {
      const response = await fetch(
        `https://ocurresmx.dev-dattmx.com/api/ocurresedit/${ocurreToEdit._id}`,
        //`http://localhost:1340/api/ocurresedit/${ocurreToEdit._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        toast.success("Registro actualizado correctamente.");
        const updatedData = data.map((item) =>
          item._id === ocurreToEdit._id ? formData : item
        );
        setData(updatedData);
      } else {
        throw new Error("Error al actualizar el registro");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al actualizar el registro.");
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="p-d-flex p-jc-center">
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-secundary"
          onClick={() => confirmEdit(rowData)}
          style={{ marginBottom: "0.5rem" }}
          text
        />
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="table-header">
      <h3>Puntos Ocurres México</h3>
      <div className="p-datatable-globalfilter-container">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar"
        />
      </div>
    </div>
  );

  return (
    <div className="datatable-margin">
      <header
        className="mb-4"
        style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
      >
        <div className="d-flex align-items-center">
          <img
            src={logo}
            alt="Ocurres México"
            className="img-fluid m-4"
            style={{ width: "250px" }}
          />
          <h1 style={{ textAlign: "right", fontSize: "40px" }}>
            Ocurres DATTMX
          </h1>
        </div>
      </header>
      <div className="card mr-4 ml-4">
        <DataTable
          value={data}
          header={header}
          globalFilter={globalFilter}
          scrollable
          scrollHeight="550px"
          tableStyle={{ minWidth: "1000px", fontSize: "14px" }}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column field="calle" header="Calle" />
          <Column field="num" header="Número" />
          <Column field="ref" header="Referencia" />
          <Column field="colonia" header="Colonia" />
          <Column field="CP" header="Código Postal" />
          <Column field="ciudad" header="Ciudad" />
          <Column field="estado" header="Estado" />
          <Column field="nombreNeg" header="Nombre del Negocio" />
          <Column field="nombreProp" header="Nombre del Propietario" />
          <Column field="nombreEnc" header="Nombre del Encargado" />
          <Column field="telContacto" header="Teléfono de Contacto" />
          <Column
            field="diasLab"
            header="Días Laborales"
            body={(rowData) => (
              <span>
                {rowData.diasLab
                  .map((dia) => {
                    switch (dia) {
                      case "Lunes":
                        return "Lun";
                      case "Martes":
                        return "Mar";
                      case "Miércoles":
                        return "Mie";
                      case "Jueves":
                        return "Jue";
                      case "Viernes":
                        return "Vie";
                      case "Sábado":
                        return "Sáb";
                      case "Domingo":
                        return "Dom";
                      default:
                        return "";
                    }
                  })
                  .join(", ")}
              </span>
            )}
          />
          <Column field="horaApertura" header="Hora de Apertura" />
          <Column field="horaCierre" header="Hora de Cierre" />
          <Column field="espacioDisp" header="Espacio Disponible" />
          <Column field="recibePaq" header="Recibe Paquetes" />
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: "8rem" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
        </DataTable>
      </div>
      {/*<Link to="/" className="mr-4 ml-4 mt-4">
        Regresar al formulario
      </Link>*/}
      <Dialog
        header="Confirmar"
        visible={deleteConfirmation}
        style={{ width: "50vw" }}
        modal
        footer={
          <div>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              onClick={hideDeleteConfirmation}
              className="p-button-secondary"
              text
              raised
            />
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={handleDeleteClick}
              autoFocus
              raised
            />
          </div>
        }
        onHide={hideDeleteConfirmation}
      >
        ¿Estás seguro de que quieres eliminar este registro?
      </Dialog>
      <Dialog
        header="Autorización Requerida"
        visible={authDialogVisible}
        style={{ width: "30vw" }}
        modal
        footer={
          <div>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              onClick={hideAuthDialog}
              className="p-button-secondary"
              text
              raised
            />
            <Button
              label="Confirmar"
              icon="pi pi-check"
              className="p-button-success"
              onClick={verifyAuthorizationKey}
              autoFocus
              raised
            />
          </div>
        }
        onHide={hideAuthDialog}
      >
        <div>
          <p>
            Por favor, ingresa la clave de autorización para eliminar el
            registro:
          </p>
          <InputText
            value={authorizationKey}
            onChange={(e) => setAuthorizationKey(e.target.value)}
            type="password"
            placeholder="Clave de autorización"
          />
          <ToastContainer />
        </div>
      </Dialog>
      <Dialog
        header="Editar Registro"
        visible={editDialogVisible}
        style={{ width: "50vw" }}
        modal
        footer={
          <div>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              onClick={hideEditDialog}
              className="p-button-secondary"
              text
              raised
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              className="p-button-success"
              onClick={() => setAuthDialogVisible(true)}
              autoFocus
              raised
            />
          </div>
        }
        onHide={hideEditDialog}
      >
        <div className="p-fluid">
          <div className="p-field mb-4">
            <label htmlFor="calle">Calle</label>
            <InputText
              id="calle"
              name="calle"
              value={formData.calle || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="num">Número</label>
            <InputText
              id="num"
              name="num"
              value={formData.num || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="ref">Referencia</label>
            <InputText
              id="ref"
              name="ref"
              value={formData.ref || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="colonia">Colonia</label>
            <InputText
              id="colonia"
              name="colonia"
              value={formData.colonia || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="CP">Código Postal</label>
            <InputText
              id="CP"
              name="CP"
              value={formData.CP || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="ciudad">Ciudad</label>
            <InputText
              id="ciudad"
              name="ciudad"
              value={formData.ciudad || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="estado">Estado</label>
            <InputText
              id="estado"
              name="estado"
              value={formData.estado || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="nombreNeg">Nombre del Negocio</label>
            <InputText
              id="nombreNeg"
              name="nombreNeg"
              value={formData.nombreNeg || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="nombreProp">Nombre del Propietario</label>
            <InputText
              id="nombreProp"
              name="nombreProp"
              value={formData.nombreProp || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="nombreEnc">Nombre del Encargado</label>
            <InputText
              id="nombreEnc"
              name="nombreEnc"
              value={formData.nombreEnc || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="telContacto">Teléfono de Contacto</label>
            <InputText
              id="telContacto"
              name="telContacto"
              value={formData.telContacto || ""}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="diasLab">Días Laborales</label>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="diasLab"
                value="Lunes"
                checked={formData.diasLab.includes("Lunes")}
                onChange={handleEditChange}
              />
              <label className="form-check-label">Lunes</label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="diasLab"
                value="Martes"
                checked={formData.diasLab.includes("Martes")}
                onChange={handleEditChange}
              />
              <label className="form-check-label">Martes</label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="diasLab"
                value="Miércoles"
                checked={formData.diasLab.includes("Miércoles")}
                onChange={handleEditChange}
              />
              <label className="form-check-label">Miércoles</label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="diasLab"
                value="Jueves"
                checked={formData.diasLab.includes("Jueves")}
                onChange={handleEditChange}
              />
              <label className="form-check-label">Jueves</label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="diasLab"
                value="Viernes"
                checked={formData.diasLab.includes("Viernes")}
                onChange={handleEditChange}
              />
              <label className="form-check-label">Viernes</label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="diasLab"
                value="Sábado"
                checked={formData.diasLab.includes("Sábado")}
                onChange={handleEditChange}
              />
              <label className="form-check-label">Sábado</label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="diasLab"
                value="Domingo"
                checked={formData.diasLab.includes("Domingo")}
                onChange={handleEditChange}
              />
              <label className="form-check-label">Domingo</label>
            </div>
          </div>
          <div className="p-field mb-4">
            <label htmlFor="horaApertura">Hora de Apertura</label>
            <input
              type="time"
              className="form-control"
              name="horaApertura"
              value={formData.horaApertura}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="horaCierre">Hora de Cierre</label>
            <input
              type="time"
              className="form-control"
              name="horaCierre"
              value={formData.horaCierre}
              onChange={handleEditChange}
            />
          </div>
          <div className="p-field mb-4">
            <label htmlFor="espacioDisp">Espacio Disponible</label>
            <select
              className="form-select"
              name="espacioDisp"
              value={formData.espacioDisp}
              onChange={handleEditChange}
            >
              <option value="">Selecciona una opción</option>
              <option value="De 1 a 5 m3">De 1 a 5 m3</option>
              <option value="De 6 a 10 m3">De 6 a 10 m3</option>
              <option value="Más de 11 m3">Más de 11 m3</option>
            </select>
          </div>
          <div className="p-field mb-4">
            <label htmlFor="recibePaq">Recibe Paquetes</label>
            <select
              className="form-select"
              name="recibePaq"
              value={formData.recibePaq}
              onChange={handleEditChange}
            >
              <option value="">Selecciona una opción</option>
              <option value="12x12x12">12x12x12</option>
              <option value="14x14x14">14x14x14</option>
              <option value="16x16x16">16x16x16</option>
              <option value="18x18x18">18x18x18</option>
              <option value="20x20x20">20x20x20</option>
              <option value="22x22x22">22x22x22</option>
              <option value="24x24x24">24x24x24</option>
              <option value="26x26x26">26x26x26</option>
              <option value="Pallets">Pallets</option>
            </select>
          </div>
        </div>
      </Dialog>
      <ToastContainer />
      <ToastContainer />
    </div>
  );
}

export default Ocurres;
