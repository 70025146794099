import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./img/DATTMXLogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [formData, setFormData] = useState({
    calle: "",
    ref: "",
    num: "",
    colonia: "",
    CP: "",
    ciudad: "",
    estado: "",
    nombreNeg: "",
    nombreProp: "",
    nombreEnc: "",
    telContacto: "",
    diasLab: [],
    horaApertura: "",
    horaCierre: "",
    espacioDisp: "",
    recibePaq: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setFormData((prevData) => ({
          ...prevData,
          diasLab: [...prevData.diasLab, value],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          diasLab: prevData.diasLab.filter((day) => day !== value),
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Verifica si algún campo está vacío
    for (const key in formData) {
      if (formData[key] === "") {
        alert("Todos los campos son obligatorios");
        return;
      }
    }
    try {
      const response = await fetch(
        "https://ocurresmx.dev-dattmx.com/api/ocurres",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      console.log(formData);
      if (response.ok) {
        toast.success("Sucursal ocurre registrada con éxito");
        setFormData({
          calle: "",
          ref: "",
          num: "",
          colonia: "",
          CP: "",
          ciudad: "",
          estado: "",
          nombreProp: "",
          nombreNeg: "",
          nombreEnc: "",
          telContacto: "",
          diasLab: [],
          horaApertura: "",
          horaCierre: "",
          espacioDisp: "",
          recibePaq: "",
        });
      } else {
        throw new Error("Error al registrar la sucursal ocurre");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al registrar la sucursal ocurre");
    }
  };

  return (
    <div>
      <header
        className="mb-4"
        style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
      >
        <div className="d-flex align-items-center">
          <img
            src={logo}
            alt="Ocurres México"
            className="img-fluid m-4"
            style={{ width: "250px" }}
          />
          <h1 style={{ textAlign: "right", fontSize: "40px" }}>
            Ocurres DATTMX
          </h1>
        </div>
      </header>
      <div className="container">
        <h2>Formulario de registro Ocurres en México</h2>
        <form onSubmit={handleSubmit}>
          <h3>Dirección del establecimiento</h3>
          <div className="mb-3">
            <label className="form-label">
              Calle:
              <input
                type="text"
                className="form-control"
                name="calle"
                value={formData.calle}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Número:
              <input
                type="text"
                className="form-control"
                name="num"
                value={formData.num}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Referencia:
              <input
                type="text"
                className="form-control"
                name="ref"
                value={formData.ref}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Colonia:
              <input
                type="text"
                className="form-control"
                name="colonia"
                value={formData.colonia}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Código Postal:
              <input
                type="text"
                className="form-control"
                name="CP"
                value={formData.CP}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Ciudad:
              <input
                type="text"
                className="form-control"
                name="ciudad"
                value={formData.ciudad}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Estado:
              <input
                type="text"
                className="form-control"
                name="estado"
                value={formData.estado}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <h3>Datos del propietario</h3>
          <div className="mb-3">
            <label className="form-label">
              Nombre del Negocio:
              <input
                type="text"
                className="form-control"
                name="nombreNeg"
                value={formData.nombreNeg}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Nombre del Propietario:
              <input
                type="text"
                className="form-control"
                name="nombreProp"
                value={formData.nombreProp}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Nombre del Encargado:
              <input
                type="text"
                className="form-control"
                name="nombreEnc"
                value={formData.nombreEnc}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Teléfono de Contacto:
              <input
                type="text"
                className="form-control"
                name="telContacto"
                value={formData.telContacto}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <h3>Horarios de atención al cliente</h3>
          <div className="mb-3">
            <label className="form-label">
              Días Laborales:
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diasLab"
                  value="Lunes"
                  checked={formData.diasLab.includes("Lunes")}
                  onChange={handleChange}
                />
                <label className="form-check-label">Lunes</label>
              </div>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diasLab"
                  value="Martes"
                  checked={formData.diasLab.includes("Martes")}
                  onChange={handleChange}
                />
                <label className="form-check-label">Martes</label>
              </div>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diasLab"
                  value="Miércoles"
                  checked={formData.diasLab.includes("Miércoles")}
                  onChange={handleChange}
                />
                <label className="form-check-label">Miércoles</label>
              </div>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diasLab"
                  value="Jueves"
                  checked={formData.diasLab.includes("Jueves")}
                  onChange={handleChange}
                />
                <label className="form-check-label">Jueves</label>
              </div>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diasLab"
                  value="Viernes"
                  checked={formData.diasLab.includes("Viernes")}
                  onChange={handleChange}
                />
                <label className="form-check-label">Viernes</label>
              </div>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diasLab"
                  value="Sábado"
                  checked={formData.diasLab.includes("Sábado")}
                  onChange={handleChange}
                />
                <label className="form-check-label">Sábado</label>
              </div>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="diasLab"
                  value="Domingo"
                  checked={formData.diasLab.includes("Domingo")}
                  onChange={handleChange}
                />
                <label className="form-check-label">Domingo</label>
              </div>
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Hora de Apertura:
              <input
                type="time"
                className="form-control"
                name="horaApertura"
                value={formData.horaApertura}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Hora de Cierre:
              <input
                type="time"
                className="form-control"
                name="horaCierre"
                value={formData.horaCierre}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <h3>Condiciones de entrega de mercancía</h3>
          <div className="mb-3">
            <label className="form-label">
              Espacio Disponible:
              <select
                className="form-select"
                name="espacioDisp"
                value={formData.espacioDisp}
                onChange={handleChange}
                required
              >
                <option value="">Selecciona una opción</option>
                <option value="De 1 a 5 m3">De 1 a 5 m3</option>
                <option value="De 6 a 10 m3">De 6 a 10 m3</option>
                <option value="Más de 11 m3">Más de 11 m3</option>
              </select>
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Recibe Paquetes hasta:
              <select
                className="form-select"
                name="recibePaq"
                value={formData.recibePaq}
                onChange={handleChange}
                required
              >
                <option value="">Selecciona una opción</option>
                <option value="12x12x12">12x12x12</option>
                <option value="14x14x14">14x14x14</option>
                <option value="16x16x16">16x16x16</option>
                <option value="18x18x18">18x18x18</option>
                <option value="20x20x20">20x20x20</option>
                <option value="22x22x22">22x22x22</option>
                <option value="24x24x24">24x24x24</option>
                <option value="26x26x26">26x26x26</option>
                <option value="Pallets">Pallets</option>
              </select>
            </label>
          </div>
          <div className="mb-3">
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </div>
        </form>
        <Link to="/ocurres" className="btn btn-secondary mb-4">
          Lista de sucursales ocurre
        </Link>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
